.header {
  background: #fff;
  padding: 12px;
  text-align: center;
}
.logo {
  width: 138px;
  height: 50px;
  background: url(/static/img/aodba-primary.svg) 0 50% no-repeat;
  display: block;
  transition: all .3s;
}
.logo-secondary {
  width: 220px;
  height: 74px;
  background: url(/static/img/aodba.svg) 0 50% no-repeat #000494;
  background-position: 20px 50%;
  &:hover, &:focus {
    opacity: .9;
    background-color: #000494;
  }
}
.menu-button {
  border: 0;
  background: transparent;
  width: 80px;
  height: 40px;
  position: relative;
  z-index: 10;
  color: #111;
  &:before,
  &:after,
  .menu-button-inner:before {
    content: '';
    width: 16px;
    height: 1px;
    background: #111;
    display: block;
    position: absolute;
    top: 14px;
    left: 4px;
    transition: .3s all;
  }
  &:after {
    top: 20px;
  }
  .menu-button-inner:before {
    top: 26px;
  }
}
body.active {
  overflow-y: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    transition: .3s all;
    z-index: 10;
  }
}
.menu-button.active {
  color: #fff;
  &:before,
  &:after,
  .menu-button-inner:before {
    background: #fff;
  }
  &:before {
    transform: rotate(45deg) translate(5px, 2px);
  }
  &:after {
    transform: rotate(-45deg) translate(2px, 1px);
  }
  .menu-button-inner:before {
    opacity: 0;
  }
}
.menu-button-inner { margin-left: 16px; }
.navigation {
  transform: translate(-220px, 0);
  position: absolute;
  width: 220px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 15;
  background: #fff;
  text-align: left;
  transition: .3s all;
}
.navigation.active {
  transform: translate(0, 0);
  overflow: scroll;
}
.navigation-link {
  color: #111;
  text-decoration: none;
  transition: all .3s;
  padding: 16px 24px;
  display: block;
  background-color: transparent;
  &:hover, &:focus {
    color: #000494;
    background-color: #d3f7ff;
    text-decoration: none;
  }
}
@media only screen and (min-width: 768px) {
  .header { text-align: left; }
  .navigation { margin-top: 0; }
}