// Fonts
@font-face {
  font-display: fallback;
  font-family: 'IBMPlexSans Regular';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/IBMPlexSans-Regular.woff2') format('woff2');
}
@font-face {
  font-display: fallback;
  font-family: 'IBMPlexSans SemiBold';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/IBMPlexSans-SemiBold.woff2') format('woff2');
}

*, :after, :before { box-sizing:border-box; }
html { -webkit-text-size-adjust: 100%; }
body {
  margin: 0;
  font-family: 'IBMPlexSans Regular', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #323232;
}
p {
  margin: 0 0 1.25rem 0;
}
a {
  text-decoration: none;
  color: #000494;
  transition: .3s all;
  &:hover, &:focus {
    color: #010101;
  }
}
strong, .strong {
  font-family: 'IBMPlexSans SemiBold', sans-serif;
}
hr {
  border: 0;
  height: 1px;
  background: #eee;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: block;
}
ul li, ol li { margin: 8px 0; }

img { max-width: 100%; }

::selection { background-color: #e0e1ff; }