figure {
    margin: 1em 0;
    content: "code";
    margin-top: 0px;
    margin-bottom: 15px;
    padding: 1.5rem 1rem;
    border: 5px #99b7f7;
    position: relative;
    background-color: #e9f2f1;
}

pre, code {
  word-break: break-word;
  white-space: pre-wrap;
  margin: 0;
}