@media only screen and (min-height: 3100px) {
  .show-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0 auto;
  }
}

@media only screen and (min-height: 3400px) {
  .show-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0 auto;
  }
}

@media only screen and (min-height: 3700px) {
  .show-3 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0 auto;
  }
}

