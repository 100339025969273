.flex { display: flex; }
.flex-order-1 { order: 1; }
.flex-order-2 { order: 2; }
.flex-order-3 { order: 3; }
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }
.flex-space-between { justify-content: space-between; }
.flex-space-around { justify-content: space-around; }
.flex-align-center { align-items: center; }
@media only screen and (min-width: 768px) {
  .flex-order-1-m { order: 1; }
  .flex-order-2-m { order: 2; }
  .flex-order-3-m { order: 3; }
  .flex-m { display: flex; }
  .flex-row-m { flex-direction: row; }
  .flex-column-m { flex-direction: column; }
  .flex-space-between-m { justify-content: space-between; }
  .flex-space-around-m { justify-content: space-around; }
  .flex-align-center-m { align-items: center; }
}

@media only screen and (min-width: 960px) {
  .flex-order-1-l { order: 1; }
  .flex-order-2-l { order: 2; }
  .flex-order-3-l { order: 3; }
  .flex-l { display: flex; }
  .flex-row-l { flex-direction: row; }
  .flex-column-l { flex-direction: column; }
  .flex-space-between-l { justify-content: space-between; }
  .flex-space-around-l { justify-content: space-around; }
  .flex-align-center-l { align-items: center; }
}