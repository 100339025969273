h1, h2, h3, h4, h5, h6 {
  font-family: "IBMPlexSans SemiBold", sans-serif;
  font-weight: 700;
  color: #000;
  margin: 0 0 1rem 0;
}
h1, .h1 {
  font-size: 32px;
  line-height: 38px;
}
h2, .h2 {
  font-size: 24px;
  line-height: 30px;
}
h3, .h3 {
  font-size: 20px;
  line-height: 26px;
}
h4, .h4 {
  font-size: 18px;
  line-height: 24px;
}
h5, .h5 {
  font-size: 16px;
  line-height: 22px;
}
h6, .h6 {
  font-size: 14px;
  line-height: 20px;
}