.padding-top-0 { padding-top: 0; }
.padding-top-0-5rem { padding-top: 0.5rem; }
.padding-top-1rem { padding-top: 1rem; }
.padding-top-1-5rem { padding-top: 1.5rem; }
.padding-top-2rem { padding-top: 2rem; }
.padding-top-3rem { padding-top: 3rem; }
.padding-top-4rem { padding-top: 4rem; }

.padding-bottom-0 { padding-bottom: 0; }
.padding-bottom-0-5rem { padding-bottom: 0.5rem; }
.padding-bottom-1rem { padding-bottom: 1rem; }
.padding-bottom-1-5rem { padding-bottom: 1.5rem; }
.padding-bottom-2rem { padding-bottom: 2rem; }
.padding-bottom-3rem { padding-bottom: 3rem; }
.padding-bottom-4rem { padding-bottom: 4rem; }

.padding-0 { padding: 0; }
.padding-0-5rem { padding: 0.5rem; }
.padding-1rem { padding: 1rem; }
.padding-1-5rem { padding: 1.5rem; }
.padding-2rem { padding: 2rem; }
.padding-3rem { padding: 3rem; }
.padding-4rem { padding: 4rem; }

@media only screen and (min-width: 768px) {
  .padding-top-0-m { padding-top: 0; }
  .padding-top-0-5rem-m { padding-top: 0.5rem; }
  .padding-top-1rem-m { padding-top: 1rem; }
  .padding-top-1-5rem-m { padding-top: 1.5rem; }
  .padding-top-2rem-m { padding-top: 2rem; }
  .padding-top-3rem-m { padding-top: 3rem; }
  .padding-top-4rem-m { padding-top: 4rem; }

  .padding-bottom-0-m { padding-bottom: 0; }
  .padding-bottom-0-5rem-m { padding-bottom: 0.5rem; }
  .padding-bottom-1rem-m { padding-bottom: 1rem; }
  .padding-bottom-1-5rem-m { padding-bottom: 1.5rem; }
  .padding-bottom-2rem-m { padding-bottom: 2rem; }
  .padding-bottom-3rem-m { padding-bottom: 3rem; }
  .padding-bottom-4rem-m { padding-bottom: 4rem; }

  .padding-0-m { padding: 0; }
  .padding-0-5rem-m { padding: 0.5rem; }
  .padding-1rem-m { padding: 1rem; }
  .padding-1-5rem-m { padding: 1.5rem; }
  .padding-2rem-m { padding: 2rem; }
  .padding-3rem-m { padding: 3rem; }
  .padding-4rem-m { padding: 4rem; }
}
@media only screen and (min-width: 960px) {
  .padding-top-0-l { padding-top: 0; }
  .padding-top-0-5rem-l { padding-top: 0.5rem; }
  .padding-top-1rem-l { padding-top: 1rem; }
  .padding-top-1-5rem-l { padding-top: 1.5rem; }
  .padding-top-2rem-l { padding-top: 2rem; }
  .padding-top-3rem-l { padding-top: 3rem; }
  .padding-top-4rem-l { padding-top: 4rem; }

  .padding-bottom-0-l { padding-bottom: 0; }
  .padding-bottom-0-5rem-l { padding-bottom: 0.5rem; }
  .padding-bottom-1rem-l { padding-bottom: 1rem; }
  .padding-bottom-1-5rem-l { padding-bottom: 1.5rem; }
  .padding-bottom-2rem-l { padding-bottom: 2rem; }
  .padding-bottom-3rem-l { padding-bottom: 3rem; }
  .padding-bottom-4rem-l { padding-bottom: 4rem; }

  .padding-0-l { padding: 0; }
  .padding-0-5rem-l { padding: 0.5rem; }
  .padding-1rem-l { padding: 1rem; }
  .padding-1-5rem-l { padding: 1.5rem; }
  .padding-2rem-l { padding: 2rem; }
  .padding-3rem-l { padding: 3rem; }
  .padding-4rem-l { padding: 4rem; }
}