.banner {
  background: transparent;
  // color: #fff;
  text-align: center;
  position: relative;
}
.banner-inner {
  padding: 50px 20px 30px 20px;
}

.banner-heading,
.banner-content {
  color: #fff;
}