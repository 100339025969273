.block { display: block; }
.inline-block { display: inline-block; }

.margin-0 { margin: 0; }

.content {
  background: #f4f4f4;
}
.card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
}

.alert {
  background-color: #ffc;
  padding: 1rem;
  border: 1px solid #ffa;
}

.tags {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tag {
  position: relative;
  margin-right: 0.5rem;
  &:before {
    content: '\2023';
    margin-right: .125rem;
  }
}
