.container {
  max-width: 1240px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .container-m {
    max-width: 1240px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 960px) {
  .container-l {
    max-width: 1240px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0 auto;
  }
}