@media only screen and (min-width: 768px) {
  .col-1-2-m { width: 50%; }
  .col-1-3-m { width: 33.33334%; }
  .col-2-3-m { width: 66.66667%; }
  .col-2-5-m { width: 40%; }
  .col-3-5-m { width: 60%; }
  .col-4-5-m { width: 75%; }
  .col-1-4-m { width: 25%; }
  .col-1-5-m { width: 20%; }
}

@media only screen and (min-width: 960px) {
  .col-1-2-l { width: 50%; }
  .col-1-3-l { width: 33.33334%; }
  .col-2-3-l { width: 66.66667%; }
  .col-2-5-l { width: 40%; }
  .col-3-5-l { width: 60%; }
  .col-4-5-l { width: 75%; }
  .col-1-4-l { width: 25%; }
  .col-1-5-l { width: 20%; }
}

@media only screen and (min-width: 1140px) {
  .col-1-2-xl { width: 50%; }
  .col-1-3-xl { width: 33.33334%; }
  .col-2-3-xl { width: 66.66667%; }
  .col-2-5-xl { width: 40%; }
  .col-3-5-xl { width: 60%; }
  .col-4-5-xl { width: 75%; }
  .col-1-4-xl { width: 25%; }
  .col-1-5-xl { width: 20%; }
}