/* Buy me a coffee button */
.bmc-button {
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  color: #fff;
  display: block;
  padding: 20px 15px 20px 50px;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(100, 100, 100, 1);
  background: url(https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg) 15px 50% no-repeat #ff8100;
}
.bmc-button:hover, .bmc-button:focus {
  text-decoration: none;
  opacity: .85;
  transform: translate(0, -2px);
}